import React, { useState } from 'react';
// import { useNavigate, Navigate } from 'react-router-dom';
import { toast } from 'react-custom-alert';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import "../css/Signup.css";
import 'react-custom-alert/dist/index.css'; // import css file from root.
import Login from './Login';
// import Home from './Home';
import { server } from '../components/environment';

export default function Signup ({setToken}) {
  // const [username, setUsername] = useState('')
  // const [usernameError, setUsernameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [password1, setPassword1] = useState('')
  const [passwordError1, setPasswordError1] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordError2, setPasswordError2] = useState('')
  const [firstName, setFirstName] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastName, setLastName] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [address, setAddress] = useState('')
  const [addressError, setAddressError] = useState('')
  const [city, setCity] = useState('')
  const [cityError, setCityError] = useState('')
  const [state, setState] = useState('')
  const [stateError, setStateError] = useState('')
  const [country, setCountry] = useState('')
  const [countryError, setCountryError] = useState('')
  const [zip, setZip] = useState('')
  const [zipError, setZipError] = useState('')

  const alertSuccess = (err) => toast.success(err);
  // const alertWarning = (err) => toast.warning(err);
  const alertError = (err) => toast.error(err);

  // const navigate = useNavigate()

  //Send the form data to the server
  async function signupUser(credentials) {
    return fetch(server + 'signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
   }

   async function loginUser(credentials) {
    return fetch(server + 'login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
   }

  const onButtonClick = async () => {
    var signupError = false;
    // if(username === "") {
    //   setUsernameError("Enter username");
    //   signupError = true;
    // } else setUsernameError("");
    if(password1 === "") {
      setPasswordError1("Enter password");
      signupError = true;
    } else setPasswordError1("");
    if(password2 === "") {
      setPasswordError2("Enter password");
      signupError = true;
    } else setPasswordError2("");
    if(password1 !== password2) {
      setPasswordError2("Passwords do not match");
      signupError = true;
    } else setPasswordError2("");
    if(email === "") {
      setEmailError("Enter email");
      signupError = true;
    } else setEmailError("");
    // Regex for valid email
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email')
      signupError = true;
    } else setEmailError("");
    if(firstName === "") {
      setFirstNameError("Enter first name");
      signupError = true;
    } else setFirstNameError("");
    if(lastName === "") {
      setLastNameError("Enter last name");
      signupError = true;
    } else setLastNameError("");
    if(address === "") {
      setAddressError("Enter address");
      signupError = true;
    } else setAddressError("");
    if(city === "") {
      setCityError("Enter city");
      signupError = true;
    } else setCityError("");
    if(state === "") {
      setStateError("Enter state");
      signupError = true;
    } else setStateError("");
    if(country === "") {
      setCountryError("Enter country");
      signupError = true;
    } else setCountryError("");
    if(zip === "") {
      setZipError("Enter zip");
      signupError = true;
    } else setZipError("");
    // Regex for valid zip code
    if (!/^\d{5}(?:[-\s]\d{4})?$/.test(zip)) {
      setZipError("Enter a valid zip");
      signupError = true;
    } else setZipError("");
    

    if(signupError) {
      return;
    }

    var userData = {
      "username": email, //this can be changed
      "password": password1,
      "email": email,
      "firstName": firstName,
      "lastName": lastName,
      "address": address,
      "city": city,
      "state": state,
      "country": country,
      "zip": zip,
      "date_created": Date.now()
    }

    // Save the user data
    var returnValue = ''
    try {
      returnValue = await signupUser(userData);
      returnValue = returnValue.result
    }
    catch (error) {
      returnValue = 1;
    }
    if(returnValue === 1) {
      alertError("Error signing up.  Try again")
      return;
    }
    else {
      alertSuccess("Signed up!")
    }

    // Login the user
    const token = await loginUser({
      "email": email,
      "password": password1
    });

    if(token.token === 'login') {
      alertError("Failed to login");
    }
    
    setToken(JSON.stringify(token)); // Set the session token

    //Re route to the home page
    // <Navigate to='/home'/>
  }

  const loginButton = async e => {
    e.preventDefault();
    setToken(JSON.stringify({"token":"login"})); // Set the session token
    <Login />
  }

  return (
    <div className="signup-wrapper" id={'mainSignupContent'}>
      <div className='signup-wrapper-head'>
        <h3>Create an Account</h3>
        <p>Access to our Dashboard</p>
      </div>
      <div className={'form-field inputContainer'}>
      <label htmlFor="email">Email:</label>
        <input
          value={email}
          placeholder="Enter your email here"
          onChange={(ev) => setEmail(ev.target.value)}
          className={'form-control inputBox'}
        />
        <label className="errorLabel">{emailError}</label>
      </div>

      {/* Passwords */}
        <div className={'form-field inputContainer'}>
        <label htmlFor="password">Password:</label>
          <input
            type='password'
            value={password1}
            placeholder="Enter your password here"
            onChange={(ev) => setPassword1(ev.target.value)}
            className={'form-control inputBox'}
          />
          <label className="errorLabel">{passwordError1}</label>
        </div>
        <div className={'form-field inputContainer'}>
          <label htmlFor="validate-password">Validate Password:</label>
          <input
            type='password'
            value={password2}
            placeholder="Validate password"
            onChange={(ev) => setPassword2(ev.target.value)}
            className={'form-control inputBox'}
          />
          <label className="errorLabel">{passwordError2}</label>
        </div>

      {/* Names */}
        <div className={'form-field inputContainer'}>
        <label htmlFor="firstName">First Name:</label>
        <input
          value={firstName}
          placeholder="Enter your first name here"
          onChange={(ev) => setFirstName(ev.target.value)}
          className={'form-control inputBox'}
        />
        <label className="errorLabel">{firstNameError}</label>
      </div>
      <div className={'form-field inputContainer'}>
      <label htmlFor="lastName">Last Name:</label>
        <input
          value={lastName}
          placeholder="Enter your last name here"
          onChange={(ev) => setLastName(ev.target.value)}
          className={'form-control inputBox'}
        />
        <label className="errorLabel">{lastNameError}</label>
      </div>

      {/* Address */}
      <div className={'form-field inputContainer'}>
        <label htmlFor="address">Address:</label>
        <input
          value={address}
          placeholder="Enter your address"
          onChange={(ev) => setAddress(ev.target.value)}
          className={'form-control inputBox'}
        />
        <label className="errorLabel">{addressError}</label>
      </div>

        <div className={'form-field inputContainer'}>
        <label htmlFor="city">City:</label>
          <input
            value={city}
            placeholder="Enter your city"
            onChange={(ev) => setCity(ev.target.value)}
            className={'form-control inputBox'}
          />
          <label className="errorLabel">{cityError}</label>
        </div>
        <div className={'form-field inputContainer'}>
        <label htmlFor="state">State:</label>
          <input
            value={state}
            placeholder="Enter your State"
            onChange={(ev) => setState(ev.target.value)}
            className={'form-control inputBox'}
          />
          <label className="errorLabel">{stateError}</label>
        </div>
        <div className={'form-field inputContainer'}>
          <label htmlFor="country">Country:</label>
          <input
            value={country}
            placeholder="Enter your country"
            onChange={(ev) => setCountry(ev.target.value)}
            className={'form-control inputBox'}
          />
          <label className="errorLabel">{countryError}</label>
        </div>
        <div className={'form-field inputContainer'}>
        <label htmlFor="zip">Zip:</label>
          <input
            value={zip}
            placeholder="Enter your zip code"
            onChange={(ev) => setZip(ev.target.value)}
            className={'form-control inputBox'}
          />
          <label className="errorLabel">{zipError}</label>
        </div>
      <div className={'d-block w-100 mb-4'}>
        <input className={'btn btn-primary w-100'} type="button" onClick={onButtonClick} value={'Create Account'} />
      </div>

      <div className='login-info'>
        <p>Already have an account? <span><Link to='/' onClick={loginButton}>Login</Link></span></p>
      </div>
    </div>
  )
}

Signup.propTypes = {
  setToken: PropTypes.func.isRequired
}