import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import { server } from './environment';

const alertError = (message) => toast.error(message);
// const alertWarning = (message) => toast.warning(message);
export const expenseCategories = ["Housing", "Transportation", "Food", "Clothing", "Healthcare", "Insurance", 
    "Travel", "Utilities", "Entertainment", "Gifts", "Other"]

// Total the expenses for each category and the total expenses across all categories
export const calculateExpenseCategories = expenseData => {
    var data = {}
    var name = ""
    var dataArray = []
    var totalExpenses = 0
    var i = 0;

    //Create an array with all the expense categories at 0
    for(i = 0; i < expenseCategories.length; i++) {
        name = expenseCategories[i]
        data[name] = parseFloat(0)
    }

    if(expenseData !== undefined && expenseData !== null && expenseData.length > 0) {
      // Iterate through all the expenses and add to the category array
      for(i = 0; i < expenseData.length; i++) {
          name = expenseData[i].category
          if(name in data) {
          data[name] += parseFloat(expenseData[i].amount)
          }
          else {
          data["Other"] += parseFloat(expenseData[i].amount)
          }
          totalExpenses += parseFloat(expenseData[i].amount)
      }
    }

    // Create an array of json objects for the circular graph
    for(i = 0; i < expenseCategories.length; i++) {
        dataArray.push({name: expenseCategories[i], data: data[expenseCategories[i]]})
    }

    return {array: dataArray, totalExpenses: totalExpenses.toFixed(2)}

}

// Read expense data and return array of expense objects
const addExpense = (data) => {
    if (!data || !data.message || data.message.length === 0) return [];

    const expensesArray = data.message.map((item) => ({
      id: item.ID,
      date: item.DATE,
      category: item.CATEGORY,
      description: item.DESCRIPTION,
      amount: parseFloat(item.AMOUNT),
      user: item.USER,
    }));

    return expensesArray
  };

// Load the expenses from the server
export const getExpenses = async (token) => {
    try {
      const response = await fetch(server + "queryExpenses", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: token.token }),
      });
      const data = await response.json();
      return addExpense(data);
    } catch {
      alertError("Error connecting to the database");
      return [];
    }
  };

// Load the summation of expenses from the server
export const getTotalExpenses = async (token) => {
  try {
    const response = await fetch(server + "queryTotalExpenses", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: token.token }),
    });
    const data = await response.json();
    return data;

  } catch (error) {
    console.log(error)
    alertError("Error connecting to the database");
    return [];
  }
};




