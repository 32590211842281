import {useEffect, useState} from 'react'
import axios from "axios";
import {usePlaidLink} from "react-plaid-link";
import { toast } from 'react-custom-alert';
import { plaidTransactionCategories } from './PlaidTransactionCategories';
import { server } from './environment';

// https://plaid.com/docs/api/link/#linktokencreate
// https://plaid.com/docs/api/products/transactions/

// axios.defaults.baseURL ="http://localhost:8000"
// axios.defaults.baseURL = "https://localhost:443/"
// axios.defaults.baseURL = "http://ec2-3-85-128-228.compute-1.amazonaws.com:8000/"
// const server = "https://localhost:443/"
const alertError = (err) => toast.error(err);

// Fetch the account data from the server
// /exchange_public_token
// /auth
// Return array of account data
async function fetchData(userToken, user) {
  console.log("Fetch Data", userToken, user)
  let accessToken = null;
  let auth = null;
  try {
    accessToken = await axios.post("/exchange_public_token", {"public_token": userToken, "username": user});
    // console.log("accessToken", accessToken.data);
  }
  catch (error) {
    console.log("Error exchanging public token from server", error);
  }

  try {
    auth = await axios.post("/auth", {access_token: accessToken.data.accessToken});
    console.log("auth data ", auth.data);

    // setAccountsArray(auth.data.accounts);
    return {accounts: auth.data.accounts, accessToken: accessToken.data.accessToken}
  }
  catch (error) {
    console.log("Error getting account info", error);
    return [];
  }
}

// Read expense data and return array of expense objects
const addPlaidTransactions = (data, username) => {
  // if (!data || !data.transactions || data.transactions.length === 0) return [];

  console.log("Data", username, data)

  const expensesArray = data.transactions.map((item) => (
    console.log("Item", plaidTransactionCategories[item.personal_finance_category.detailed]),
    {
    id: Date.parse(item.date.replace("'", "\"")),
    date: Date.parse(item.date.replace("'", "\"")),
    category: plaidTransactionCategories[item.personal_finance_category.detailed],
    description: item.name.replace("'", "\""),
    amount: parseFloat(item.amount),
    user: username,
  }));

  return expensesArray
};

// Save the returned plaid transactions
async function saveTransactions(transactions, username) {
  console.log("Save transactions")

  let transactionArray = await addPlaidTransactions(transactions, username)

  if(transactionArray.length > 0) {
    try {
      await fetch( axios.defaults.baseURL + "addExpenses", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(transactionArray),
      });
    } catch {
      alertError("Error updating expenses to the database");
    }
  }

  console.log("Transaction array", transactionArray)
}

function PlaidAuth({publicToken, username}) {
  const [accountsArray, setAccountsArray] = useState([]);
  let accountData = null;
  console.log("Plaid auth", publicToken, username)

  // Send the user's public token to receive account info
  useEffect(() => {
    async function query() {
      console.log("Use effect", publicToken, username)

      try {
        accountData = await fetchData(publicToken, username);
        // console.log("Account data", accountData)
        setAccountsArray(accountData.accounts);
        // console.log("Account token", accountData.accessToken)
      }
      catch (error) {
        console.log("Error exchanging public token from server", error);
      }

      if(accountData !== null) {
        let transactionsObject = await queryTransactions(accountData.accessToken);
        console.log("Trans", transactionsObject)

        saveTransactions(transactionsObject, username);

      }
    }

    query()
  }, [publicToken, username]);

  console.log("Account", accountsArray[0])

  if (accountsArray[0]) {
      return accountsArray && (
        <>
          <p>Plaid Auth</p>
          <p>Public token: {publicToken}</p>
          <p>Account number: {accountsArray[0].name}</p>
          {/* <p>Balance: {accountsArray[0].balances}</p> */}
          {/* <p>Account: {JSON.stringify(accountsArray)}</p> */}
        </>
      );
    }
}

// Print array of accounts data
function listAccounts(accounts) {
  if (accounts != undefined || !accounts.length || accounts.length < 1) {
    console.log("No accounts found")
    return
  }
  return (
    <>
    {/* Nested map loop
          Iterate each object in the accounts array
          Iterate each account and print the account_id
      */}
    {accounts.map((item) => 
      (item.accounts.map((singleAccount) => 
        (<p key={singleAccount.account_id}>Saved account - {singleAccount.account_id}</p>))
      ))}
    
    {/* Print the first 5 transactions */}
    {accounts.map((item) =>  (
      // item.transactions.slice().map((transaction) => 
        item.transactions.map((transaction) => 
        (<p key={transaction.transaction_id}>{transaction.date}{'\t'}- {transaction.name}{'\t'}- {transaction.amount}</p>))
    ))}
    </>
  )
}

// Query Plaid using all the saved access tokens
async function queryTransactions(token) {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
  const transactions = await axios.post("/getPlaidTransactions", {'accessToken': token, 'begin': firstDay, 'end':today}); // Query each access token
  const transactionArray = await transactions.data;

  if (transactionArray !== undefined) {
    return transactionArray; // Return the array of transactions
  }
  else {
    console.log("No response from plaid transactions - checkPublicToken")
    return [];
  }
}

// Check for any saved public tokens
// /getPlaidTokens
// /getPlaidTransactions
async function checkPublicToken(token, username) {
  var accountsArray = [];
  var accessTokenResponse = await axios.post("/getPlaidToken", {username: username});

  var promises = [];
  if (accessTokenResponse.data.length > 0) {
    //Query each access token for the transactions
    promises = accessTokenResponse.data.map(async (object, index) => {
      let transactions = await queryTransactions(object.token);
      if(transactions !== null) {
        accountsArray.push(transactions)
      }
    });
  }
  else {
    console.log("Transactions not found")
  }

  await Promise.all(promises); // Wait for all promises to resolve
  if(accountsArray.length > 0) {
    // setAccounts(accountsArray);
    console.log("Done public tokens", accountsArray)
    return accountsArray;
  }
  else {
    console.log("No accounts found")
    return [];
  }
};

// Authenticate and create an initial link token
async function createLinkToken(username) {
  try {
    const response = await axios.post("/create_link_token", {username: username});
    // setLinkToken(response.data.link_token); // Initiate a page refresh
    return response.data.link_token;
  }
  catch(e) {
    alertError("Error connecting to the Plaid service"); //Can't connect to Plaid
    return null;
  }
}

function Link(token) {
  const username = token.token;
  const [linkToken, setLinkToken] = useState(); // Used to initialize the link
  const [publicToken, setPublicToken] = useState();
  const [accounts, setAccounts] = useState([]); // Saved accounts access tokens

  console.log("Link Refresh")

  //Check for an existing token
  useEffect(() => {
    async function fetchExisting() {
      setAccounts(await checkPublicToken(token, username)); // Check for any saved tokens
      console.log("Accounts", accounts)
    }
    fetchExisting()
   }, [token, username]);

  // on the first load, Call the server for a link token
  useEffect(() => {
    async function fetchLinkToken() {
      setLinkToken(await createLinkToken(username)); // Create a temporary link token
    }
    fetchLinkToken();
  }, [username]);

  // Exchange the link token for a public token
  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (public_token, metadata) => {
      console.log("usePlaidLink", public_token);
      setPublicToken(public_token);
    },
  });

  // If publicToken true, call PlaidAuth component, else run the button code
  return (
    <div>
      {accounts.length ? (listAccounts(accounts)) : (<p>No saved accounts</p>)}
      {publicToken ? (<PlaidAuth publicToken={publicToken} username={username}/>) : (
        <button onClick={() => open()} disabled={!ready}>
          Connect a bank account
        </button>
      )}
    </div>
  )
}

export default Link
