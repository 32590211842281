import React, { useState } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell, Tooltip } from "recharts";
import {
  BarChart,
  Bar,
  // Rectangle,
  XAxis,
  YAxis,
  Legend
} from "recharts";

// https://refine.dev/blog/recharts/#create-a-pie-chart-using-recharts

//Make a Label that is the percent of total expenses
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const CustomTooltipPie = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="CustomTooltipPie">
        <p className="label">{`${payload[0].name} : $${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export const CustomTooltipBar = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="CustomTooltipBar">
        <p className="label">Balance</p>
        <div style={{ display: "flex", flexDirection: "column", padding: "5px" }}>
          {payload.map((pld) => (
            <div key={pld.name} style={{ display: "flex", flexDirection: "row"}}>
              <div>{pld.dataKey}:</div>
              <div style={{ color: pld.fill }}>${pld.value}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export const PieChartComponent = (dataArray) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    // Pastel colors
    const COLORS = ["#CBAACB", "#FFAEA5", "#FEE1E8", "#FED7C3", "#8FCACA", "#CCE2CB", "#FFD8BE", "#A2E1DB", "#55CBCD", "#97C1A9", "#ECD5E3"];
    const onPieEnter = (_, index) => {
        setActiveIndex(index);
    };

    const filteredData = dataArray.dataArray.filter(item => item.data > 0);

  return (
    <ResponsiveContainer aspect={1}>
        <PieChart>
            <Pie
                activeIndex={activeIndex}
                data={filteredData}
                dataKey="data"
                label={renderCustomizedLabel}
                onMouseEnter={onPieEnter}
                style={{ cursor: 'pointer', outline: 'none' }} // Ensure no outline on focus
            >
                {dataArray.dataArray.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                
            </Pie>
            <Tooltip content={<CustomTooltipPie />} cursor={{ fill: "transparent" }} />
        </PieChart>
    </ResponsiveContainer>
  );
};

export const BarChartComponent = ( dataArray ) => {
    return (
        <ResponsiveContainer aspect={1}>
        <BarChart
          data={dataArray.dataArray}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip content={<CustomTooltipBar payload={dataArray.dataArray}/>} cursor={{ fill: "transparent" }} />
          <Legend />
          <Bar dataKey="Income" fill="#97C1A9" />
          <Bar dataKey="Expenses" fill="#FF968A" />
        </BarChart>
      </ResponsiveContainer>
    );
  };