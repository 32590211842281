import { ToastContainer } from 'react-custom-alert';
// import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css'; 
import React, {useState, useEffect} from 'react';
import { Routes, Route } from 'react-router-dom';

import './App.css';

import Navbar from "./pages/Navbar";
import Expense from './pages/Expense';
import Income from './pages/Income';
import Signup from './pages/Signup';
import About from './pages/About';
import Login from './pages/Login';
import Account from './pages/Account';
import Link from './components/Link'
import Timeline from './pages/Timeline'
import Overview from './pages/Overview';
// import useTokenSession from './components/useTokenSession';
// import useTokenLocal from './components/useTokenLocal';

function App() {
  const [token, setToken] = useState(JSON.parse(localStorage.getItem('token')))

  let userToken = JSON.parse(token)

  useEffect(() => {
    // Store name in local storage whenever it changes
    localStorage.setItem('token', JSON.stringify(token));
  }, [token]);

  return (
    <div className="App">
      {/* Render Navbar only once */}
      <Navbar token={token} setToken={setToken} />

      <div id="main-content">
        {(() => {
          // Go to the signup page
          if (!token || !userToken.token || userToken.token === "signup") {
            return (
              <div>
                <Signup setToken={setToken}/>
                <ToastContainer floatingTime={5000} />
              </div>
            );
          }
          // Force the login screen
          else if (userToken.token === "login") {
            return (
              <div>
                <Login setToken={setToken} />
                <ToastContainer floatingTime={5000} />
              </div>
            );
          }
          

          // Return the main page
          return (
            <Routes>
              <Route path='/' element={<Overview token={userToken.token} />} />
              <Route path='/index' element={<Overview token={userToken.token} />} />
              <Route path='/Overview' element={<Overview token={userToken.token} />} />
              <Route path='/Expense' element={<Expense token={userToken.token} />} />
              <Route path='/Income' element={<Income token={userToken.token} />} /> 
              <Route path='/signup' element={<Signup setToken={setToken}/>} />
              <Route path='/about' element={<About />} />
              <Route path='/login' element={<Login setToken={setToken} />} />
              <Route path='/account' element={<Account />} />
              <Route path='/link' element={<Link token={userToken.token} />} />
              <Route path='/timeline' element={<Timeline token={userToken.token}/>}></Route>
            </Routes>
          );
        })()}
      </div>

      <ToastContainer floatingTime={5000} />
    </div>
  );
}

export default App;
