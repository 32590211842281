import React from 'react';

function About() {
    return (
    <div>
        <title>About - Expense Tracker</title>
      <div>
        <h1>About File Expense Tracker</h1>
        <p>This is a simple expense tracker application built using Node.js and Express.</p>
        <p>You can use it to:</p>
        <ul>
          <li>Add new expenses with a description and amount.</li>
          <li>View a list of your added expenses.</li>
          <li>Delete expenses from the list.</li>
        </ul>
      </div>
    </div>
    );
}

export default About;