import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import { server } from './environment';

const alertError = (message) => toast.error(message);
// const alertWarning = (message) => toast.warning(message);
// export const incomeCategories = ["Housing", "Transportation", "Food", "Clothing", "Healthcare", "Insurance", 
//     "Travel", "Utilities", "Entertainment", "Gifts", "Other"]
export const incomeCategories = ["Salary", "Business", "Investment", "Rental", "Royalties", "Other"]
export const frequencyCategories = ["Daily", "Weekly", "Bi-Weekly", "Monthly", "Yearly"]

// Total the expenses for each category and the total expenses across all categories
export const calculateIncomeCategories = incomeData => {
    var data = {}
    var name = ""
    var dataArray = []
    var totalIncome = 0
    var i = 0;

    //Create an array with all the income categories at 0
    for(i = 0; i < incomeCategories.length; i++) {
        name = incomeCategories[i]
        data[name] = parseFloat(0)
    }

    // No incomes or the array is undefined
    if(!incomeData || incomeData.length === 0) {
      return {array: dataArray, totalIncomes: 0}
    }

    if(incomeData !== undefined && incomeData !== null && incomeData.length > 0) {
      // Iterate through all the expenses and add to the category array
      for(i = 0; i < incomeData.length; i++) {
          name = incomeData[i].category
          if(name in data) {
            data[name] += parseFloat(incomeData[i].amount)
          }
          else {
            data["Other"] += parseFloat(incomeData[i].amount)
          }
          totalIncome += parseFloat(incomeData[i].amount)
      }
    }

    // Create an array of json objects for the circular graph
    for(i = 0; i < incomeCategories.length; i++) {
        dataArray.push({name: incomeCategories[i], data: data[incomeCategories[i]]})
    }

    return {array: dataArray, totalIncomes: totalIncome.toFixed(2)}
}

// Read income data and return array of income objects
const addIncome = (data) => {
    if (!data || !data.message || data.message.length === 0) return [];

    const incomeArray = data.message.map((item) => ({
      id: item.ID,
      date: item.DATE,
      category: item.CATEGORY,
      description: item.DESCRIPTION,
      amount: parseFloat(item.AMOUNT),
      user: item.USER,
      frequency: item.FREQUENCY
    }));

    return incomeArray
  };

// Load the incomes from the server
export const getIncomes = async (token) => {
    try {
      const response = await fetch(server + "queryIncomes", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ user: token.token }),
      });
      const data = await response.json();
      return addIncome(data);
    } catch {
      alertError("Error connecting to the database");
      return [];
    }
  };

// Load the summation of incomes from the server
export const getTotalIncomes = async (token) => {
  try {
    const response = await fetch(server + "queryTotalIncomes", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: token.token }),
    });
    const data = await response.json();
    return data;

  } catch (error) {
    console.log(error)
    alertError("Error connecting to the database");
    return [];
  }
};




