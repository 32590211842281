import React, { useState, useEffect } from 'react';
import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import { BarChartComponent, PieChartComponent } from '../components/Charts'
import {getIncomes, incomeCategories, calculateIncomeCategories, frequencyCategories} from "../components/Incomes"
import "../css/Income.css";
import { server } from '../components/environment';
import {getTotalExpenses} from '../components/Expenses';

export default function Income(token) {
  const alertError = (message) => toast.error(message);
  const alertWarning = (message) => toast.warning(message);
  const [showAddModal, setShowAddModal] = useState(false); // Add income modal
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Delete button conrirmation
  const [deleteID, setDeleteID] = useState(null); // The row the user selects to delete
  const [fadeClass, setFadeClass] = useState('');

  const [incomes, setIncomes] = useState([]);
  const [incomeCategoriesData, setIncomeCategoriesData] = useState([])
  const [balanceSheet, setBalanceSheet] = useState([])

  const openAddModal = () => {
    setShowAddModal(true);
    setTimeout(() => setFadeClass('fade-in'), 0);
  };

  const closeAddModal = () => {
    setFadeClass('fade-out');
    setTimeout(() => {
      setShowAddModal(false);
      setFadeClass('');
    }, 300);
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
    setTimeout(() => setFadeClass('fade-in'), 0);
  };

  const closeDeleteModal = () => {
    setFadeClass('fade-out');
    setTimeout(() => {
      setShowDeleteModal(false);
      setFadeClass('');
    }, 300);
  };

  // Load the incomes from the server
  useEffect(() => {
    const getData = async () => {
      setIncomes(await getIncomes(token));
    }

    getData();
  }, [token]);

  // Re calculate the income categories when the income array changes
  useEffect(() => {
    let totalExpense = 0;
    const getData = async () => {
      // Incomes
      let data = calculateIncomeCategories(incomes)
      setIncomeCategoriesData(data.array)
      // Total expenses
      let returnData = await getTotalExpenses(token);
      totalExpense = parseFloat(returnData.total);

      setBalanceSheet([{ name: "Balance", "Expenses": totalExpense, "Income": data.totalIncomes}])
    }
    
    getData();

    
  }, [incomes])

  // Re calculate the income categories when the income array changes
  // useEffect(() => {
  //   let data = calculateIncomeCategories(incomes)
  //   // let totalExpenses = getTotalExpenses(token).total
  //   let totalExpenses = 0;
  //   setIncomeCategoriesData(data.array)
  //   setBalanceSheet([{ name: "Balance", "Expenses": totalExpenses, "Income": data.totalIncomes}])
  // }, [incomes])

  const addIncomeButton = () => {
    const amount = document.getElementById("amount").value;
    const description = document.getElementById("description").value;
    const category = document.getElementById("category").value;
    const frequency = document.getElementById("frequency").value;

    // Show error
    let error = 0;
    if (!amount || !/^[0-9]+$/.test(amount)) {
      document.getElementById("amount").className = !amount ? "red-border" : "";
      error = 1;
    }
    if (!category) {
      document.getElementById("category").className = !category ? "red-border" : "";
      error = 1;
    }
    if (!description) {
      document.getElementById("description").className = !description ? "red-border" : "";
      error = 1;
    }
    if (!frequency) {
      document.getElementById("frequency").className = !frequency ? "red-border" : "";
      error = 1;
    }
    if(error === 1) {
      alertWarning("Error adding data");
      return
    }

    const newIncomeItem = {
      id: Date.now(),
      date: Date.now(),
      category: category,
      description: description,
      amount: parseFloat(amount),
      user: token.token,
      frequency: frequency
    };

    // Add to the incomes array
    setIncomes([...incomes, newIncomeItem]);
    //Remove any error text
    document.getElementById("amount").className = "";
    document.getElementById("description").className = "";
    document.getElementById("category").className = "";
    document.getElementById("frequency").className = "";
    closeAddModal();
  };

  const updateIncomesButton = async () => {
    //Remove all incomes
    if(!incomes || incomes.length === 0) {
      try {
        await fetch( server + "removeAllIncomes", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({"id": token.token}),
        });
      } catch {
        alertError("Error updating incomes to the database");
      }
    }
    //Update incomes
    else {
      try {
        await fetch( server + "updateIncomes", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(incomes),
        });
      } catch {
        alertError("Error updating incomes to the database");
      }
    }
  };

  const saveSingleIncome = async (incomeData) => {
    if (!incomeData) return;

    try {
      await fetch(server + "saveSingleIncome", {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(incomeData),
      });
    } catch {
      alertError("Error saving income");
    }
  };

  const saveSingleIncomeButton = async (id) => {
    const incomeRow = document.getElementById(`incomeRow_${id}`);
    const fields = incomeRow.querySelectorAll(".incomeDescription, .incomeAmount");
    const selectFields = incomeRow.querySelectorAll(".incomeCategory, .incomeFrequency")
    const button = document.getElementById(`editIncomeButton_${id}`);
    const icon = button.querySelector("img");
    const background = incomeRow.style.backgroundColor;
  
    // Toggle contenteditable and icon
    const isEditable = fields[0].getAttribute('contenteditable') === 'true';
  
    if (isEditable) {
      // Disable editing and save changes
      fields.forEach((field) => {
        field.setAttribute('contenteditable', 'false');
        field.style.backgroundColor = background;
      });
      selectFields.forEach((field) => {
        field.setAttribute('contenteditable', 'false');
        field.style.backgroundColor = background;
        field.disabled = true;
      });
  
      // Change icon back to edit
      icon.src = '/imgs/edit.svg';
      icon.alt = 'Edit';
  
      // Save the updated data to server
      const incomeData = {
        id: id,
        description: incomeRow.querySelector('.incomeDescription').textContent,
        amount: parseFloat(incomeRow.querySelector('.incomeAmount').textContent.substring(1)),
        category: incomeRow.querySelector('.incomeCategory').value,
        user: token.token,
        frequency: incomeRow.querySelector('.incomeFrequency').value
      };
      await saveSingleIncome(incomeData);

      // Update the incomes array locally and cause the use-state to reload the page
      setIncomes(prevItems => prevItems.map(item => 
        item.id === id ? { ...item, description: incomeData.description, amount: incomeData.amount, category: incomeData.category, frequency: incomeData.frequency } : item
      ));

    } else {
      // Enable editing
      fields.forEach((field) => {
        field.setAttribute('contenteditable', 'true');
        field.style.backgroundColor = 'yellow';
      });
      selectFields.forEach((field) => {
        field.setAttribute('contenteditable', 'true');
        field.style.backgroundColor = 'yellow';
        field.disabled = false;
      });
      
      // Change icon to save
      icon.src = '/imgs/save.svg';
      icon.alt = 'Save';
    }
  };

  // Handle the <select> for Income Categories
  const handleCategorySelectChange = (e, incomeData) => {
    incomeData.category = e.target.value

    // Update the incomes array locally and cause the use-state to reload the page
    setIncomes(prevItems => prevItems.map(item => 
      item.id === incomeData.id ? { ...item, description: incomeData.description, amount: incomeData.amount, category: incomeData.category, frequency: incomeData.frequency } : item
    ));

 };

 // Handle the <select> for Income Frequencies
 const handleFrequencySelectChange = (e, incomeData) => {
  incomeData.frequency = e.target.value

  // Update the incomes array locally and cause the use-state to reload the page
  setIncomes(prevItems => prevItems.map(item => 
    item.id === incomeData.id ? { ...item, description: incomeData.description, amount: incomeData.amount, category: incomeData.category, frequency: incomeData.frequency } : item
  ));

};

  // Removes the income from the array and closes the modal
  const deleteIncome = (id) => {
    setIncomes(incomes.filter((income) => income.id !== id))
    closeDeleteModal()
  };

  // Sets the row ID and brings up the modal for confirmation
  const deleteIncomeButton = (id) => {
    setDeleteID(id)
    openDeleteModal()
    // closeDeleteModal()
  }

  // Temporary text
  if (!incomes) return <div>Loading ...</div>;

  return (
    <div className='container'>
      <div className="charts">
        <div className="chart">
          Incomes
          <PieChartComponent dataArray={incomeCategoriesData} />
        </div>
        <div className='chart'>
          Expense vs. incomes
          <BarChartComponent dataArray={balanceSheet} />
        </div>
          
      </div>

      <div className="content-page-header">
        {/* <div className="incomes"> */}
        <h5>Incomes</h5>
        <div className="list-btn">
          <button className="btn btn-primary" onClick={openAddModal}>
            <span className="mr-3">
              <img className="icon" src="/imgs/add.svg" alt="Add Icon" />
            </span>
            Add Incomes
          </button>
        </div>
      </div>

      <table className="table">
        <thead>
          <tr>
            {/* <th>Sr #</th> */}
            <th>Income Date</th>
            <th>Category</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Frequency</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {incomes.map((income, index) => (
            <tr key={income.id} id={`incomeRow_${income.id}`}>
              {/* <td>{income.id}</td> */}
              <td className="incomeID">{new Date(income.date).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}</td>
              {/* List of categories to select from */}
              <td>
                <select className="incomeCategory" disabled={true} contentEditable={false} value={income.category} onChange={(e) => handleCategorySelectChange(e, income)}>
                  {incomeCategories.map((category) => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </td>
              <td className="incomeDescription" contentEditable={false}>
                {income.description}
              </td>
              <td className="incomeAmount" contentEditable={false}>
                ${income.amount}
              </td>
              {/* List of frequencies to select from */}
              <td>
                <select className="incomeFrequency" disabled={true} contentEditable={false} value={income.frequency} onChange={(e) => handleFrequencySelectChange(e, income)}>
                  {frequencyCategories.map((category) => (
                    <option key={category} value={category}>{category}</option>
                  ))}
                </select>
              </td>
              <td>
                <button
                  id={`editIncomeButton_${income.id}`}
                  className="btn btn-outline-primary btn-sm mr-3"
                  onClick={() => saveSingleIncomeButton(income.id)}
                >
                  <img src="/imgs/edit.svg" alt="Edit" className="icon" />
                </button>
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={() => {deleteIncomeButton(income.id)}}
                >
                  <img src="/imgs/delete.svg" alt="Delete" className="icon" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for adding an income */}
      {showAddModal && (
        <div className={`modal-overlay ${fadeClass}`}>
          <div className={`modal ${fadeClass}`}>
            <div className='modal-header'>
              <h2>Add New Income</h2>
              <button onClick={closeAddModal}>
                <span className="mr-3">
                  <img className="icon" src="/imgs/cross.svg" alt="Close Icon" />
                </span>
              </button>
            </div>
            <div className='form-field'>
              <label htmlFor="category">Category:</label>
              <select className='form-control' id="category" name="category">
                {incomeCategories.map((category) => (
                  <option key={category} value={category}>{category}</option>
                ))}
              </select>
            </div>
            <div className='form-field'>
              <label htmlFor="description">Description:</label>
              <input className='form-control' type="text" id="description" name="description" placeholder='Enter Description' autoComplete='false' />
            </div>
            <div className='form-field'>
              <label htmlFor="amount">Amount:</label>
              <input className='form-control' type="text" id="amount" name="amount" autoComplete='false' placeholder='Enter Amount' defaultValue="0" />
            </div>
            <div className='form-field'>
              <label htmlFor="frequency">Frequency:</label>
              <select className='form-control' id="frequency" name="frequency">
                {frequencyCategories.map((frequency) => (
                  <option key={frequency} value={frequency}>{frequency}</option>
                ))}
              </select>
            </div>
            <div className="modal-buttons">
              <button className="btn btn-primary d-block w-100" onClick={addIncomeButton}>Add Income</button>
            </div>
          </div>
        </div>
      )}

      {/* Delete button confirmation modal */}
      {showDeleteModal && (
        <div className={`modal-overlay ${fadeClass}`}>
          <div className={`modal ${fadeClass}`}>
            <div className='modal-header'>
              <h2>Are you sure?</h2>
              <button onClick={closeDeleteModal}>
                <span className="mr-3">
                  <img className="icon" src="/imgs/cross.svg" alt="Close Icon" />
                </span>
              </button>
            </div>
            <div className="modal-buttons">
              <button className="btn btn-primary d-block w-100" onClick={() => {deleteIncome(deleteID)}}>Delete Income</button>
            </div>
          </div>
        </div>
      )}

      {/* Save all incomes to the server */}
      <div className='save-all-main'>
        <button className='btn btn-primary' onClick={updateIncomesButton}>Save All</button>
      </div>
      {/* </div> */}
    </div>
  );
}

