export const plaidTransactionCategories = 
    {
        "INCOME_DIVIDENDS": "INCOME",
        "INCOME_INTEREST_EARNED": "INCOME",
        "INCOME_RETIREMENT_PENSION": "INCOME",
        "INCOME_TAX_REFUND": "INCOME",
        "INCOME_UNEMPLOYMENT": "INCOME",
        "INCOME_WAGES": "INCOME",
        "INCOME_OTHER_INCOME": "INCOME",
        "TRANSFER_IN_CASH_ADVANCES_AND_LOANS": "TRANSFER_IN",
        "TRANSFER_IN_DEPOSIT": "TRANSFER_IN",
        "TRANSFER_IN_INVESTMENT_AND_RETIREMENT_FUNDS": "TRANSFER_IN",
        "TRANSFER_IN_SAVINGS": "TRANSFER_IN",
        "TRANSFER_IN_ACCOUNT_TRANSFER": "TRANSFER_IN",
        "TRANSFER_IN_OTHER_TRANSFER_IN": "TRANSFER_IN",
        "TRANSFER_OUT_INVESTMENT_AND_RETIREMENT_FUNDS": "TRANSFER_OUT",
        "TRANSFER_OUT_SAVINGS": "TRANSFER_OUT",
        "TRANSFER_OUT_WITHDRAWAL": "TRANSFER_OUT",
        "TRANSFER_OUT_ACCOUNT_TRANSFER": "TRANSFER_OUT",
        "TRANSFER_OUT_OTHER_TRANSFER_OUT": "TRANSFER_OUT",
        "LOAN_PAYMENTS_CAR_PAYMENT": "LOAN_PAYMENTS",
        "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT": "LOAN_PAYMENTS",
        "LOAN_PAYMENTS_PERSONAL_LOAN_PAYMENT": "LOAN_PAYMENTS",
        "LOAN_PAYMENTS_MORTGAGE_PAYMENT": "LOAN_PAYMENTS",
        "LOAN_PAYMENTS_STUDENT_LOAN_PAYMENT": "LOAN_PAYMENTS",
        "LOAN_PAYMENTS_OTHER_PAYMENT": "LOAN_PAYMENTS",
        "BANK_FEES_ATM_FEES": "BANK_FEES",
        "BANK_FEES_FOREIGN_TRANSACTION_FEES": "BANK_FEES",
        "BANK_FEES_INSUFFICIENT_FUNDS": "BANK_FEES",
        "BANK_FEES_INTEREST_CHARGE": "BANK_FEES",
        "BANK_FEES_OVERDRAFT_FEES": "BANK_FEES",
        "BANK_FEES_OTHER_BANK_FEES": "BANK_FEES",
        "ENTERTAINMENT_CASINOS_AND_GAMBLING": "Entertainment",
        "ENTERTAINMENT_MUSIC_AND_AUDIO": "Entertainment",
        "ENTERTAINMENT_SPORTING_EVENTS_AMUSEMENT_PARKS_AND_MUSEUMS": "Entertainment",
        "ENTERTAINMENT_TV_AND_MOVIES": "Entertainment",
        "ENTERTAINMENT_VIDEO_GAMES": "Entertainment",
        "ENTERTAINMENT_OTHER_ENTERTAINMENT": "Entertainment",
        "FOOD_AND_DRINK_BEER_WINE_AND_LIQUOR": "Food",
        "FOOD_AND_DRINK_COFFEE": "Food",
        "FOOD_AND_DRINK_FAST_FOOD": "Food",
        "FOOD_AND_DRINK_GROCERIES": "Food",
        "FOOD_AND_DRINK_RESTAURANT": "Food",
        "FOOD_AND_DRINK_VENDING_MACHINES": "Food",
        "FOOD_AND_DRINK_OTHER_FOOD_AND_DRINK": "Food",
        "GENERAL_MERCHANDISE_BOOKSTORES_AND_NEWSSTANDS": "Other",
        "GENERAL_MERCHANDISE_CLOTHING_AND_ACCESSORIES": "Clothing",
        "GENERAL_MERCHANDISE_CONVENIENCE_STORES": "Other",
        "GENERAL_MERCHANDISE_DEPARTMENT_STORES": "Other",
        "GENERAL_MERCHANDISE_DISCOUNT_STORES": "Other",
        "GENERAL_MERCHANDISE_ELECTRONICS": "Other",
        "GENERAL_MERCHANDISE_GIFTS_AND_NOVELTIES": "Gifts",
        "GENERAL_MERCHANDISE_OFFICE_SUPPLIES": "Other",
        "GENERAL_MERCHANDISE_ONLINE_MARKETPLACES": "Other",
        "GENERAL_MERCHANDISE_PET_SUPPLIES": "Other",
        "GENERAL_MERCHANDISE_SPORTING_GOODS": "Other",
        "GENERAL_MERCHANDISE_SUPERSTORES": "Other",
        "GENERAL_MERCHANDISE_TOBACCO_AND_VAPE": "Other",
        "GENERAL_MERCHANDISE_OTHER_GENERAL_MERCHANDISE": "Other",
        "HOME_IMPROVEMENT_FURNITURE": "Housing",
        "HOME_IMPROVEMENT_HARDWARE": "Housing",
        "HOME_IMPROVEMENT_REPAIR_AND_MAINTENANCE": "Housing",
        "HOME_IMPROVEMENT_SECURITY": "Housing",
        "HOME_IMPROVEMENT_OTHER_HOME_IMPROVEMENT": "Housing",
        "MEDICAL_DENTAL_CARE": "Health Care",
        "MEDICAL_EYE_CARE": "Health Care",
        "MEDICAL_NURSING_CARE": "Health Care",
        "MEDICAL_PHARMACIES_AND_SUPPLEMENTS": "Health Care",
        "MEDICAL_PRIMARY_CARE": "Health Care",
        "MEDICAL_VETERINARY_SERVICES": "Health Care",
        "MEDICAL_OTHER_MEDICAL": "Health Care",
        "PERSONAL_CARE_GYMS_AND_FITNESS_CENTERS": "Health Care",
        "PERSONAL_CARE_HAIR_AND_BEAUTY": "Health Care",
        "PERSONAL_CARE_LAUNDRY_AND_DRY_CLEANING": "Health Care",
        "PERSONAL_CARE_OTHER_PERSONAL_CARE": "Health Care",
        "GENERAL_SERVICES_ACCOUNTING_AND_FINANCIAL_PLANNING": "Other",
        "GENERAL_SERVICES_AUTOMOTIVE": "Transportation",
        "GENERAL_SERVICES_CHILDCARE": "Other",
        "GENERAL_SERVICES_CONSULTING_AND_LEGAL": "Other",
        "GENERAL_SERVICES_EDUCATION": "Other",
        "GENERAL_SERVICES_INSURANCE": "Insurance",
        "GENERAL_SERVICES_POSTAGE_AND_SHIPPING": "Other",
        "GENERAL_SERVICES_STORAGE": "Other",
        "GENERAL_SERVICES_OTHER_GENERAL_SERVICES": "Other",
        "GOVERNMENT_AND_NON_PROFIT_DONATIONS": "Other",
        "GOVERNMENT_AND_NON_PROFIT_GOVERNMENT_DEPARTMENTS_AND_AGENCIES": "Other",
        "GOVERNMENT_AND_NON_PROFIT_TAX_PAYMENT": "Other",
        "GOVERNMENT_AND_NON_PROFIT_OTHER_GOVERNMENT_AND_NON_PROFIT": "Other",
        "TRANSPORTATION_BIKES_AND_SCOOTERS": "Transportation",
        "TRANSPORTATION_GAS": "Transportation",
        "TRANSPORTATION_PARKING": "Transportation",
        "TRANSPORTATION_PUBLIC_TRANSIT": "Transportation",
        "TRANSPORTATION_TAXIS_AND_RIDE_SHARES": "Transportation",
        "TRANSPORTATION_TOLLS": "Transportation",
        "TRANSPORTATION_OTHER_TRANSPORTATION": "Transportation",
        "TRAVEL_FLIGHTS": "Travel",
        "TRAVEL_LODGING": "Travel",
        "TRAVEL_RENTAL_CARS": "Travel",
        "TRAVEL_OTHER_TRAVEL": "Travel",
        "RENT_AND_UTILITIES_GAS_AND_ELECTRICITY": "Utilities",
        "RENT_AND_UTILITIES_INTERNET_AND_CABLE": "Utilities",
        "RENT_AND_UTILITIES_RENT": "Utilities",
        "RENT_AND_UTILITIES_SEWAGE_AND_WASTE_MANAGEMENT": "Utilities",
        "RENT_AND_UTILITIES_TELEPHONE": "Utilities",
        "RENT_AND_UTILITIES_WATER": "Utilities",
        "RENT_AND_UTILITIES_OTHER_UTILITIES": "Utilities"
    }