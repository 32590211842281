import React from 'react';
// import {useState} from 'react';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';

import '../css/Timeline.css'

export default function Timeline (token) {
    return (
        <div>
            Timeline

            <div>Grade School</div>
            <div>High School</div>
            <div>Save for car, dates</div>
            <div>Savings Account</div>
            <div>College</div>
            <div>Scholarships</div>
            <div>Debt / Loans</div>
            <div>Credit Cards</div>
            <div>Investing</div>
            <div>First Job</div>
            <div>Car</div>
            <div>Apartment</div>
            <div>Insurance</div>
            <div>House</div>
            <div>Marriage</div>
            <div>Kids</div>
            <div>Retirement</div>

        </div>
    )
}
