import React, { useState, useEffect } from 'react';
import { toast } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import { BarChartComponent, PieChartComponent } from '../components/Charts'

import {getExpenses, expenseCategories, calculateExpenseCategories} from "../components/Expenses"
import { getIncomes, incomeCategories, calculateIncomeCategories } from '../components/Incomes';
import "../css/Expense.css";
import "../css/Charts.css"; 
import { server } from '../components/environment';
import { ResponsiveContainer } from 'recharts';

const alertError = (message) => toast.error(message);
const alertWarning = (message) => toast.warning(message);

export default function Overview(token) {
    const [expenses, setExpenses] = useState([]);
    const [expenseCategoriesData, setExpenseCategoriesData] = useState([])
    const [incomes, setIncomes] = useState([])
    const [incomeCategoriesData, setIncomeCategoriesData] = useState([])
    const [balanceSheet, setBalanceSheet] = useState([])

    // Load the expenses from the server
    useEffect(() => {
        const getData = async () => {
            setExpenses(await getExpenses(token));
            setIncomes(await getIncomes(token))
        }

        getData();
    }, [token]);

    // Re calculate the expense categories when the expense array changes
      useEffect(() => {
        let data = calculateExpenseCategories(expenses)
        let expenseTotal = data.totalExpenses
        setExpenseCategoriesData(data.array)
        data = calculateIncomeCategories(incomes)
        setIncomeCategoriesData(data.array)
        setBalanceSheet([{ name: "Balance", "Income": data.totalIncomes, "Expenses": expenseTotal}])
      }, [expenses, incomes])


    return (
        <div className = 'container'>
            <h1>Overview</h1>
            <p>
                This is the overview page.
            </p>

            <div className="charts">
                <ResponsiveContainer className='chart'>
                    Income vs. Expenses
                    <BarChartComponent dataArray={balanceSheet} />
                </ResponsiveContainer>
                <ResponsiveContainer className="chart">
                    Incomes
                    <PieChartComponent dataArray={incomeCategoriesData} />
                </ResponsiveContainer>
                <ResponsiveContainer className="chart">
                    Expenses
                    <PieChartComponent dataArray={expenseCategoriesData} />
                </ResponsiveContainer>
            </div>
        </div>
        
    );
}