import React, { useState } from 'react';
// import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-custom-alert';
import { Link } from 'react-router-dom';
import { server } from '../components/environment';

import '../css/Login.css';
import 'react-custom-alert/dist/index.css'; // import css file from root.

async function loginUser(credentials) {
  return fetch(server + 'login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
 }

export default function Login({setToken}) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  // toast methods : info | success | warning | error
  // const alertInfo = (err) => toast.info(err);
  // const alertSuccess = (err) => toast.success(err);
  // const alertWarning = (err) => toast.warning(err);
  const alertError = (err) => toast.error(err);

  const submitUsrPassButton = async e => {
    e.preventDefault();

    if(email === "") {
      // alertError("Enter email");
      setEmailError("Enter email");
    }
    else setEmailError("");

    if(password === "") {
      // alertError("Enter password");
      setPasswordError("Enter password");
    }
    else setPasswordError("");

    // Regex for valid email
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError('Please enter a valid email')
      return
    }

    let token = {token: ''}
    try {
      token = await loginUser({
        "email": email,
        "password": password
      });
    }
    catch (error) {
      token.token = 'login';
    }

    if(token.token === 'login') {
      alertError("Failed to login");
    }

    setToken(JSON.stringify(token)); // Set the session token
  }

  const signupButton = async e => {
    e.preventDefault();
    setToken(JSON.stringify({"token":"signup"})); // Set the session token
    <signup />
  }

  return(
    <div className="login-wrapper">
      <div className='login-wrapper-head'>
        <h3>Login</h3>
        <p>Access to our Dashboard</p>
      </div>
      <form className='w-100' onSubmit={submitUsrPassButton}>
        {/* email */}
        <div className={'form-field inputContainer'}>
        <label htmlFor="email">Email:</label>
          <input
            value={email}
            placeholder="Enter your email here"
            onChange={(ev) => setEmail(ev.target.value)}
            className={'form-control inputBox'}
          />
          <label className="errorLabel">{emailError}</label>
        </div>
        {/* Password */}
        <div className={'form-field inputContainer'}>
        <label htmlFor="password">Password:</label>
          <input
            type = 'password'
            value={password}
            placeholder="Enter your password here"
            onChange={(ev) => setPassword(ev.target.value)}
            className={'form-control inputBox'}
          />
          <label className="errorLabel">{passwordError}</label>
        </div>
        <div className='d-block w-100 mb-4'>
          <button className='btn btn-primary w-100' type="submit">Login</button>
        </div>
      </form>
      <div className='login-info'>
        <p>Don't have an account yet? <span><Link to='/' onClick={signupButton}>Register</Link></span></p>
      </div>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}


