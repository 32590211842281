import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../css/Navbar.css';

function logOut(token) {
  token.setToken(JSON.stringify({ token: 'login' }));
}

function signupButton(token) {
  token.setToken(JSON.stringify({ token: 'signup' }));
}

function userLoggedOn(token, isDropdownVisible, toggleDropdown) {
  let userToken = JSON.parse(token.token);
  if (!token.token || userToken.token === "signup") {
    return (
      <li className="main-navbar__menu__item">
        <Link
          className="main-navbar__menu__item__link mr-0 btn btn-primary no-hover"
          onClick={() => logOut(token)}
          to="/"
        >
          <span className="mr-3">
            <img className="icon" src="/imgs/login.svg" alt="Signup Icon" />
          </span>
          Log In
        </Link>
      </li>
    );
  } else if (userToken.token === "login" || userToken.token === "false") {
    return (
      <li className="main-navbar__menu__item">
        <Link
          className="main-navbar__menu__item__link mr-0 btn btn-primary no-hover"
          onClick={() => signupButton(token)}
          to="/signup"
        >
          <span className="mr-3">
            <img className="icon" src="/imgs/signup.svg" alt="Signup Icon" />
          </span>
          Signup
        </Link>
      </li>
    );
  } else {
    return (
      <li className="main-navbar__menu__item">
        <Link
          className="main-navbar__menu__item__link mr-0 no-hover"
          to="/account"
          onClick={toggleDropdown}
        >
          <div className="account-info">
            <span className="user-img">
              <img
                src="/imgs/nouser_612x612.jpg"
                alt="img"
                className="profilesidebar"
              />
              <span className="animate-circle"></span>
            </span>
            <span className="user-content">
              {/* <span className="user-details">Admin</span> */}
              <span className="user-name">{userToken.firstName} {userToken.lastName}</span>
            </span>
            <span>
              <img
                src="/imgs/down-arrow.png"
                alt="img"
                className="Dropdown"
              />
            </span>
          </div>
        </Link>
        {isDropdownVisible && (
          <div
            className="dropdown-menu menu-drop-user show"
            style={{
              position: "absolute",
              inset: "0px 0px auto auto",
              margin: "5px",
              transform: "translate3d(-2.5px, 66px, 0px)",
            }}
            data-popper-placement="bottom-end"
          >
            <div className="profilemenu">
              <div className="subscription-menu">
                <ul>
                  <li>
                    <Link
                      className="main-navbar__menu__item__link mr-0"
                      to="/account"
                    >
                      <span className="df ac mr-3">
                        <img
                          className="icon"
                          src="/imgs/profile.svg"
                          alt="Profile Icon"
                        />
                      </span>
                      Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="main-navbar__menu__item__link mr-0"
                      to="/"
                    >
                      <span className="df ac mr-3">
                        <img
                          className="icon"
                          src="/imgs/settings.svg"
                          alt="Settings Icon"
                        />
                      </span>
                      Settings
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="subscription-logout">
                <ul>
                  <li className="pb-0">
                    <Link
                      className="main-navbar__menu__item__link mr-0"
                      onClick={() => logOut(token)}
                      to="/"
                    >
                      <span className="df ac mr-3">
                        <img
                          className="icon"
                          src="/imgs/logout.svg"
                          alt="Logout Icon"
                        />
                      </span>
                      Log Out
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </li>
    );
  }
}

function toggleOverlay(){
  let menu = document.getElementsByClassName('overlay-menu')[0]
  if (menu.style.display === "block") {
    menu.style.display = "none";
  } else {
    menu.style.display = "block";
  }
}

function Navbar(token) {
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  // Toggle dropdown visibility
  const toggleDropdown = (e) => {
    e.preventDefault();
    setDropdownVisible((prevState) => !prevState);
  };

  // Hide dropdown when clicking outside
  const hideDropdown = (e) => {
    if (
      !e.target.closest(".account-info") &&
      !e.target.closest(".menu-drop-user")
    ) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", hideDropdown);
    return () => {
      document.removeEventListener("click", hideDropdown);
    };
  }, []);

  return (
    <nav className="main-navbar" id="navbar">
      <div className="container main-navbar__navbar-wrapper">
        <div className="main-navbar__logo-wrapper">
          <Link to="/" className="main-navbar__menu__item__link">
            <img
              className="main-navbar__logo-img"
              src="/logos/logo_orange_green_no_text192.ico"
              alt="Financial Freedom"
            />
          </Link>
        </div>
        <div className="main-navbar__middle">
          <ul className="main-navbar__menu" id="menu">
            <li className="main-navbar__menu__item">
              <Link className="main-navbar__menu__item__link" to="/Overview">
                Overview
              </Link>
            </li>
            <li className="main-navbar__menu__item">
              <Link className="main-navbar__menu__item__link" to="/Income">
                Incomes
              </Link>
            </li>
            <li className="main-navbar__menu__item">
              <Link className="main-navbar__menu__item__link" to="/Expense">
                Expenses
              </Link>
            </li>
            <li className="main-navbar__menu__item">
              <Link className="main-navbar__menu__item__link mr-0" to="/timeline">
                Timeline
              </Link>
            </li>
            <li className="main-navbar__menu__item">
              <Link className="main-navbar__menu__item__link mr-0" to="/about">
                About
              </Link>
            </li>
          </ul>
        </div>
        <ul className="main-navbar__menu" id="menu">
          {userLoggedOn(token, isDropdownVisible, toggleDropdown)}
        </ul>
      </div>
      <div className="menuIcon" onClick={toggleOverlay}>
        <span className="icon icon-bars"></span>
        <span className="icon icon-bars overlay"></span>
        <ul className="overlay-menu" >
          <li>
            <Link className="main-navbar__menu__item__link" to="/Overview">
              Overview
            </Link>
          </li>
          <li>
            <Link className="main-navbar__menu__item__link" to="/Income">
              Incomes
            </Link>
          </li>
          <li>
            <Link className="main-navbar__menu__item__link" to="/Expense">
              Expenses
            </Link>
          </li>
          <li>
            <Link className="main-navbar__menu__item__link" to="/Timeline">
              Timeline
            </Link>
          </li>
          <li>
            <Link className="main-navbar__menu__item__link" to="/About">
              About
            </Link>
          </li>
          {userLoggedOn(token, isDropdownVisible, toggleDropdown)}
        </ul>
          

      </div>
    </nav>
  );
}

Navbar.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Navbar;
